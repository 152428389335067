import { Container } from '@mantine/core';
import React from 'react';
import Floater from '../Floaters/Floater';
import { ProjectCard } from './ProjectCard';

function Projects() {
  return (
    <div style={{ position: 'relative' }}>
      <Container id="projects" style={{ paddingTop: '10rem', marginBottom: '12rem' }}>
        <ProjectCard />
      </Container>
      <Floater />
    </div>
  );
}

export default Projects;
