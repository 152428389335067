import { createStyles, Card, Image, Text, Group } from '@mantine/core';
import FCC from '../../pages/assets/fcc_algos.png';
import FSO from '../../pages/assets/fso.png';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'start',
    marginBottom: '8rem',
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      height: '600px',
      width: '320px',
      margin: 'auto',
    },
    height: '520px',
    width: '400px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },
}));

const data = [
  {
    school: 'University of Helsinki',
    image: FSO,
    courses: [
      'Programming a server with NodeJS and Express',
      'State Management with Redux',
      'GraphQL',
      'Typescript',
    ],
    grade: '5',
    credits: '9',
    link: 'https://studies.cs.helsinki.fi/stats/api/certificate/fullstackopen/en/5096bc7b2ce9377349b4378b3ce237f6',
  },
  {
    school: 'FreeCodeCamp.org',
    image: FCC,
    courses: [
      'Responsive Web Design Certification (300 hours)',
      'Javascript Algorithms and Data Structures Certification (300 hours)',
      'Frontend Libraries Certification (300 hours)',
      'APIs and Microservices Certification (300 hours)',
    ],
    grade: '',
    credits: '',
    link: '',
  },
];

export function EducationCard() {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {data.map((d, i) => (
        <Card key={d.school} p="lg" radius="lg" className={classes.card}>
          <Card.Section style={{ padding: 12 }}>
            <Image src={d.image} alt={d.school} height={300} radius="md" />
          </Card.Section>

          <Group position="apart" mt="xl">
            <Text size="sm" weight={700} className={classes.title}>
              {d.school}
            </Text>
          </Group>
          {d.courses.map((c, i) => (
            <Text key={c} mt="sm" mb="md" color="dimmed" size="xs">
              {c}
            </Text>
          ))}
        </Card>
      ))}
    </div>
  );
}
