import React from 'react';
import { Container, createStyles, Text, Title } from '@mantine/core';
import { EducationCard } from './EducationCard';
import Me from '../../pages/assets/me_headshot.jpg';

const useStyles = createStyles((theme) => ({
  container: {
    height: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '90px',
    paddingBottom: '5rem',
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
  img: {
    borderRadius: '50%',
    width: '200px',
    height: '200px',
  },
}));

//test redeploy comment

function About() {
  const { classes } = useStyles();
  return (
    <Container>
      <div id="about" className={classes.container}>
        <Title order={1} align="center" style={{ marginBottom: '12px' }}>
          Richard Perrotta
        </Title>
        <Title order={4} align="center" style={{ marginBottom: '60px' }}>
          Full Stack Engineer
        </Title>
        <div style={{ marginBottom: '2rem' }}>
          <img className={classes.img} src={Me} alt="me" />
        </div>
        <div className="abouttext">
          <Text size="lg" style={{ margin: '2rem' }}>
            Welcome to my site -- I'm glad you've made it here! 😃
          </Text>
          <Text size="lg" style={{ margin: '2rem' }}>
            I'm high agency, self-motivated, and recently earned AWS Developer & Solutions Architect
            certifications. My experience includes working in the Javascript, Typescript, ReactJS,
            NodeJS, and AWS Cloud ecosystems, along with some .NET and C#. I always welcome an
            opportunity to grow both the breadth and depth of my skills in areas that bring the most
            value to the projects I'm working on. I approach complex problems with optimism, hard
            work, and a sense of ownership and responsibility for the products I ship.
          </Text>
          <Text size="lg" style={{ margin: '2rem' }}>
            An overview of my skills include:
          </Text>
          <Text size="lg" style={{ margin: '2rem' }}>
            <em>Frontend</em>: managing application state, authentication flows, handling async
            requests and responses, handling error and loading states, data caching, tabular data
            display and charting, complex form data collection, secured routing, role-based route
            access, subscription-based feature access, creating and publishing reusable components,
            custom hooks and HOCs, file uploads, image compression and manipulation, theming and
            styling, e2e and unit tests
          </Text>
          <Text size="lg" style={{ margin: '2rem' }}>
            <em>Backend</em>: AWS Cloud, API creation and maintainance, integrations with 3rd party
            APIs and services, database querying and scripting, data modeling, scheduled batch
            processing, containerization, headless browser debugging, logging and monitoring
          </Text>
          <Text size="lg" style={{ margin: '2rem' }}>
            <em>DevOps & Soft Skills</em>: CI/CD, version control, meetings with stakeholders and
            product, team collaboration, code reviews, problem solving, adaptability, defining and
            refining stories and requirements, mentoring junior developers, providing application
            support
          </Text>
        </div>
      </div>
    </Container>
  );
}

export default About;
