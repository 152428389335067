import React from 'react';
import { Layout } from '../components/Layout/Layout';
import '@fontsource/homemade-apple';
import Welcome from '../components/Welcome/Welcome';
import About from '../components/About/About';
import Projects from '../components/Projects/Projects';

export default function HomePage() {
  return (
    <Layout>
      <main>
        {/* <Welcome /> */}
        <About />
        <Projects />
      </main>
    </Layout>
  );
}
