import { Modal, useMantineTheme, List, ThemeIcon, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconStar } from '@tabler/icons';

function ProjectDetailsModal({ opened, onClose, content }) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={<Title order={1}>{content?.title}</Title>}
      size="lg"
      fullScreen={isMobile}
      overflow="outside"
      overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      transition="fade"
      transitionDuration={600}
      transitionTimingFunction="ease"
    >
      <Title order={4} style={{ marginBottom: '18px' }}>
        Features:
      </Title>
      <List
        spacing="xl"
        size="sm"
        center
        icon={
          <ThemeIcon color="orange" size={16} radius="xl">
            <IconStar size={12} />
          </ThemeIcon>
        }
      >
        {content?.features.map((t) => (
          <List.Item>{t}</List.Item>
        ))}
      </List>
    </Modal>
  );
}

export default ProjectDetailsModal;
