import { createStyles } from '@mantine/core';
import './floater.css';
import JavascriptLogo from '../../pages/assets/techLogos/javascript.svg';
import ReactLogo from '../../pages/assets/techLogos/react.svg';
import TypescriptLogo from '../../pages/assets/techLogos/typescript.svg';
import VSCodeLogo from '../../pages/assets/techLogos/vscode.svg';
import CSharpLogo from '../../pages/assets/techLogos/csharp.svg';
import NodeLogo from '../../pages/assets/techLogos/node.svg';
import ReduxLogo from '../../pages/assets/techLogos/redux.svg';
import MUILogo from '../../pages/assets/techLogos/mui.svg';
import DotNetLogo from '../../pages/assets/techLogos/dot-net.svg';
import TailwindLogo from '../../pages/assets/techLogos/tailwind.svg';
import GatsbyLogo from '../../pages/assets/techLogos/gatsby.svg';
import NextLogo from '../../pages/assets/techLogos/next.svg';

const useStyles = createStyles((theme) => ({
  techIcon: {
    height: '50px',
    width: '50px',
    borderRadius: '10%',
    objectFit: 'contain',
  },
}));

const techItemsTop = [
  {
    id: 'csharp',
    src: CSharpLogo,
  },
  {
    id: 'react',
    src: ReactLogo,
  },
  {
    id: 'ts',
    src: TypescriptLogo,
  },
  {
    id: 'vscode',
    src: VSCodeLogo,
  },
  {
    id: 'js',
    src: JavascriptLogo,
  },

  {
    id: 'next',
    src: NextLogo,
  },
];

const techItemsBottom = [
  {
    id: 'node',
    src: NodeLogo,
  },
  {
    id: 'redux',
    src: ReduxLogo,
  },
  {
    id: 'mui',
    src: MUILogo,
  },
  {
    id: 'react2',
    src: ReactLogo,
  },
  {
    id: 'tailwind',
    src: TailwindLogo,
  },
  {
    id: 'gatsby',
    src: GatsbyLogo,
  },
];

function Floater() {
  const { classes } = useStyles();
  return (
    <ul className="circles">
      {techItemsTop.map((t, i) => (
        <li key={`${t}${i}top`}>
          <img
            alt={t.id}
            src={t.src}
            className={classes.techIcon}
            style={{ width: 40, height: 40 }}
          />
        </li>
      ))}
      {techItemsBottom.map((t, i) => (
        <li key={`${t}${i}bottom`}>
          <img
            alt={t.id}
            src={t.src}
            className={classes.techIcon}
            style={{ width: 40, height: 40 }}
          />
        </li>
      ))}
    </ul>
  );
}

export default Floater;
