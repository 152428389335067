import adpostFeatured from '../../pages/assets/featured/adpostFeatured.svg';
import zBestFeatured from '../../pages/assets/featured/zbestpest_logo.svg';
import ggcFeatured from '../../pages/assets/featured/ggcFeatured.svg';
import askFernFeatured from '../../pages/assets/featured/askFernFeatured.svg';
import pizzaPizzaFeatured from '../../pages/assets/featured/pizzaPizzaFeatured.png';
import npmFeatured from '../../pages/assets/featured/npmFeatured.svg';

export const data = [
  {
    id: 'react-opening-hours',
    title: 'React Opening Hours',
    link: 'https://sprightly-cendol-934003.netlify.app/',
    isActive: true,
    desc_short:
      'This project is an open-source NPM package that allows developers to easily plug-in and style a UI to collect 7-day open/close hours in their forms. Compatible in both JS and TS projects with type-safety. Zero dependencies out-of-the-box',
    desc_full:
      'This project is an open-source NPM package that allows developers to easily plug-in and style a UI to collect 7-day open/close hours in their forms',
    featured_image: npmFeatured,
    images: [],
    tech: [
      'React',
      'Typescript',
      'Javascript',
      'npm',
      'docusaurus',
      'css',
      'git',
      'semver',
      'Github Actions',
      'Jest',
    ],
    features: [
      'Encapsulated logic. It just works.',
      'Zero dependencies and small package size',
      'Internal values are exported to the parent on every change',
      'Swappable label, day button, copy button, and select elements using renderProps pattern',
      'Type-safe',
      'Various styling strategies supported including className and inline-styling to target internal elements',
      'Vertical/horizontal layouts can be applied conditionally to handle responsive designs',
      'Visit the documentation for more details :)',
    ],
    contribution: '',
  },
  {
    id: 'adpost',
    title: 'Adpost',
    link: 'https://adpost.ai',
    isActive: true,
    desc_short:
      'This project is both a customer-facing analytics dashboard/content management suite, as well as an administrative user management system. My current full-time responsiblities are focused on developing this system.',
    desc_full:
      "This project is both a customer-facing analytics dashboard/content management suite, as well as an administrative user management system.  Customers can view analytics related to their ad campaign impressions and engagements, as well as create and update the content of their static landing pages published in an alternative business directory. Administrative users can create and edit users, view individual user analytics dashboards, create and edit users' content published in associated static pages (published in an external directory), and send bulk email reports to groups of users.  Additionally, administrative users have access to functionality that can highlight or disable certain features on the users' published business directory pages",
    featured_image: adpostFeatured,
    images: [],
    tech: [
      'React',
      'Typescript',
      'Redux',
      'RTK Query',
      'RH Form',
      'AWS Cognito',
      'Material-UI',
      'Tailwind',
    ],
    features: [
      'Authentication and authorization',
      'Reusable, intuitive, and performant UI components',
      'Analytics data visualizations with fixed and custom date range selection',
      'Online Ad and Business Profile directory creation UIs',
      'Custom form controls which include text input, combo box selections, custom Google-inspired hours of operation selection, custom Material UI Date Range picker, and various image uploads with tools to drag n drop, crop, rotate, and compress images.',
      'Fetch, post, transform, manipulate, and manage data from/to backend APIs',
      'Caching and cache invalidation via RTK Query mutation and queries APIs',
      'Role-Based Access Control for User vs Admin functionality',
      'Batch email reporting',
      'Dynamic and flexible global theming designed to handle white-labeled branding and seamless re-skinning capabilities. Leverages MUI Theme APIs',
    ],
    contribution: '',
  },
  {
    id: 'zbestpest',
    title: 'Z Best Pest',
    link: 'https://www.zbestpestnj.com',
    isActive: true,
    desc_short:
      "This project is an SEO-friendly landing page developed for a local pest control company with the aim to promote exposure in the local market and provide a bridge to 3rd party pest management software. It's simple, stylish, and performant.",
    desc_full:
      "This project is an SEO-friendly landing page developed for a local pest control company with the aim to promote exposure in the local market and provide a bridge to 3rd party pest management software. It's simple, stylish, and performant.",
    featured_image: zBestFeatured,
    images: [],
    tech: ['React', 'SEO', 'a11y', 'Netlify', 'Styled-components', 'Framer motion', 'Tailwind'],
    features: [
      'Fully responsive from mobile to large desktop',
      'SEO and a11y-optimized',
      'Stylish scrolling with animations',
    ],
    contribution: '',
  },
  {
    id: 'ggc',
    title: 'GGC External',
    link: null,
    isActive: true,
    noLinkText: 'Private Internal',
    desc_short:
      'This project is an internal application built for a large regional petrochemical company. It allows tracking of various material receiving, transfer, and sales transactions between multiple internal systems and 3rd party vendors.',
    desc_full:
      'This project is an internal application built for a large regional petrochemical company. It allows tracking of various material receiving, transfer, and sales transactions between multiple internal systems and 3rd party vendors.',
    featured_image: ggcFeatured,
    images: [],
    tech: [
      'Javascript',
      'React',
      'Redux',
      '.Net Core',
      'C#',
      'Oracle PS/SQL',
      'RTK Query',
      'Material-UI',
    ],
    features: [
      'Build user-friendly UI components using React, Redux and Material UI.',
      'View, edit, delete, and export various forms of tabular data',
      'Custom wrapped reusable React components based on Material UI v5 date pickers, autocomplete, select, checkboxes, radio buttons, textfield, and dialogs',
      'Build APIs using ASP.NET Core with both Entity Framework Core and Dapper ORMs',
      'Write SQL queries and scripts to perform tasks on both Oracle SQL and Microsoft SQL Server databases',
      'Role-Based Access Control with corresponding front end modules and backend APIs',
      'Theme Builder module allowing administrative users to create, modify, and delete dynamic system-wide themes that end-users can then select, apply, and save.',
      'Documentation module for creating, updating, and deleting instructional content',
    ],
    contribution: '',
  },
  {
    id: 'askfern',
    title: 'Ask Fern',
    isActive: false,
    link: null,
    noLinkText: 'Not in production',
    desc_short:
      "This project, developed for a counselor at a Thai university, is was both an Android app and web app. It was hosted on Google Cloud Platform's App Engine. It was an anonymous forum for Thai language speakers to discuss sensitive social/psychological topics.",
    desc_full:
      "This project, developed for a counselor at a Thai university, is both an Android app and web app. The server is hosted on Google Cloud Platform's App Engine. The Android app and web app share the same server. It's an anonymous forum for Thai language speakers to post questions and comments about sensitive social/psychological topics.",
    featured_image: askFernFeatured,
    images: [],
    tech: [
      'Javascript',
      'React',
      'Redux',
      'React Native',
      'NodeJS',
      'Mongo DB',
      'Express',
      'Material-UI',
    ],
    features: [
      "Token authentication / local storage caching', 'Dark/light mode option for web, and Android dark/light mode is supported and inherited by the user's Android OS display settings",
      'Create, name, and edit avatar (character svgs provided by @bigheadsreact)',
      'Track and visualize daily moods with pie and line charts',
      "Runs a cron job once daily to prevent gaps in user mood data by adding a default mood if the user hasn't recorded their mood that day",
      'User activity is scored according to a point system and and achieving higher levels unlocks further avatar customizations',
      'Post to forum anonymously',
      'Comment and reply to posts',
      'View pending and answered posts',
      'Flag inappropriate comments for admin review',
      '"Heart" anonymous posts to show support',
    ],
    contribution: '',
  },
  // {
  //   id: 'pizzapizza',
  //   title: 'Pizza Pizza',
  //   isActive: false,
  //   noLinkText: 'Not in production',
  //   link: null,
  //   desc_short:
  //     'This project is a prototype for an online pizza ordering app. It uses the Google Places API and the Javascript Geolocation API to provide users with pizza delivery restaurants near them. Users can browse nearby restaurants and place an order.',
  //   desc_full:
  //     "This project is an online pizza ordering and delivery app. It uses the Google Places API and the Javascript Geolocation API to provide pizza delivery restaurants near the user's current location. User's can then browse the nearby restaurants and place an order.",
  //   featured_image: pizzaPizzaFeatured,
  //   images: [],
  //   tech: [
  //     'Javascript',
  //     'React',
  //     'Redux',
  //     'PassportJS',
  //     'Google Places API',
  //     'Mongo DB',
  //     'Express',
  //     'Material-UI',
  //   ],
  //   features: [
  //     'Google OAUTH2.0 user authentication with password encryption',
  //     'Local email/password authentication',
  //     'Browse nearby pizza delivery restaurants based on geolocation coordinates',
  //     'View/browse restaurant locations on embedded Google Map',
  //     "Distance between restaurant and user's current location,price-level, rating, and open/close status shown for each restaurant",
  //     'Browse restaurant reviews',
  //     'Place an order',
  //     'Automatic email confirmation upon registration and order placement',
  //     'View order history',
  //     'Active orders show live progress and status (preparing, out-for-delivery, delivered)',
  //     'Add/remove favorite restaurants',
  //     'Apply promotional codes to receive discounts',
  //     'Add/edit delivery addresses',
  //     'Add/edit credit/debit cards',
  //     'Pay with cash or credit and optionally add a tip for the delivery driver',
  //     'Session cookie generated, stored in Mongo Store, and used for authentication (express-session, connect-mongo)',
  //   ],
  //   contribution: '',
  // },
];
