import { IconHeart } from '@tabler/icons';
import { Card, Image, Text, Group, Badge, Button, createStyles, Anchor } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { data } from './data';
import ProjectDetailsModal from './ProjectDetailsModal';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    height: 600,
    minWidth: 300,
    maxWidth: 300,
    margin: 'auto',
  },

  section: {
    padding: 12,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

export function ProjectCard() {
  const { classes, theme } = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ height: 600, display: 'flex' }}>
      <Carousel
        slideSize="33.33%"
        height="100%"
        sx={{ flex: 1 }}
        controlSize={40}
        draggable
        slideGap="sm"
        breakpoints={[
          { maxWidth: 'md', slideSize: '50%' },
          { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
        ]}
        align="start"
        loop
        slidesToScroll={1}
      >
        {data.map((d, i) => (
          <Carousel.Slide key={d.title}>
            <Card key={d.title} withBorder radius="md" p="md" className={classes.card}>
              <Card.Section style={{ padding: '24px' }}>
                <Image src={d.featured_image} alt={d.title} height={160} fit="contain" />
              </Card.Section>

              <Card.Section className={classes.section} mt="md">
                {d.isActive && d.link ? (
                  <Anchor href={d.link} target="_blank" aria-label="View the project">
                    <Group position="apart">
                      <Text size="lg" weight={500}>
                        {d.title}
                      </Text>
                      <Badge
                        size="sm"
                        variant="gradient"
                        style={{ cursor: 'pointer' }}
                        gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                      >
                        {'Live'}
                      </Badge>
                    </Group>
                  </Anchor>
                ) : (
                  <Group position="apart">
                    <Text size="lg" weight={500}>
                      {d.title}
                    </Text>
                    <Badge
                      size="sm"
                      variant="gradient"
                      gradient={
                        d.isActive
                          ? { from: 'teal', to: 'lime', deg: 105 }
                          : { from: 'orange', to: 'red' }
                      }
                    >
                      {d.noLinkText}
                    </Badge>
                  </Group>
                )}

                <Text size="sm" mt="xs">
                  {d.desc_short}
                </Text>
              </Card.Section>
              <Card.Section className={classes.section}>
                <Group spacing="xs" align="center" position="center">
                  {d.tech.map((t) => (
                    <Badge
                      variant="dot"
                      size="sm"
                      color={theme.colorScheme === 'dark' ? 'orange' : 'lime'}
                      key={t}
                    >
                      {t}
                    </Badge>
                  ))}
                </Group>
              </Card.Section>
              <div>
                <Group mt="md">
                  <Button
                    radius="md"
                    style={{ flex: 1 }}
                    onClick={() => {
                      setOpen(true);
                      setDialogContent(d);
                    }}
                  >
                    Show details
                  </Button>
                </Group>
              </div>
            </Card>
          </Carousel.Slide>
        ))}
      </Carousel>
      <ProjectDetailsModal opened={open} onClose={handleClose} content={dialogContent} />
    </div>
  );
}
