import React from 'react';
import Programmer from '../../pages/assets/undraw_programming_re_kg9v.svg';
import { createStyles, Title } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    margin: 'auto',
    flexWrap: 'wrap',
    verticalAlign: 'middle',
    overflowY: 'hidden',
  },
}));

const Welcome = () => {
  return (
    <div className="container" style={{ height: '100vh', overflow: 'hidden', paddingTop: '10rem' }}>
      <Title order={1} align="center" style={{ marginBottom: '12px' }}>
        Richard Perrotta
      </Title>
      <Title order={4} align="center" style={{ marginBottom: '60px' }}>
        Full Stack Engineer
      </Title>
    </div>
  );
};

export default Welcome;
